export type NavigationItem = {
  name: string;
  label?: string;
  icon?: string;
  enabled?: boolean;
  admin?: boolean;
  notify?: boolean;
  intake?: boolean;
  paneExpanded?: boolean;
  formName?: string;
  errorKey?: string;
  hideFromMenu?: boolean;
};

export const navigation: NavigationItem[] = [
  {
    name: 'cutlist',
    icon: 'list_alt',
    intake: true,
    paneExpanded: true,
    formName: 'cutlistAppForm',
    errorKey: 'order:cutlist.invalidWarning',
  },
  {
    name: 'project',
    enabled: true,
    icon: 'summarize',
    intake: true,
    paneExpanded: true,
  },
  {
    name: 'messages',
    paneExpanded: true,
    icon: 'chat_bubble_outline',
  },
  {
    name: 'variants',
    icon: 'view_carousel',
    admin: true,
  },
  {
    name: 'files',
    icon: 'upload_file',
    enabled: true,
    intake: true,
    formName: 'uploadForm',
    errorKey: 'order-files:designFiles.noFilesUploadedWarning',
  },
  {
    name: 'files-new',
    icon: 'upload_file',
    enabled: true,
    admin: true,
    paneExpanded: true,
  },
  {
    name: 'files-edit',
    icon: 'build',
    enabled: true,
    admin: true,
    hideFromMenu: true,
  },
  {
    name: 'define-paths',
    icon: 'route',
    enabled: true,
    admin: true,
    hideFromMenu: true,
  },
  {
    name: 'pdf-split',
    icon: 'build',
    enabled: true,
    admin: true,
    hideFromMenu: true,
    paneExpanded: true,
  },
  {
    name: 'feedback',
    icon: 'try',
    intake: true,
  },
  {
    name: 'options',
    icon: 'summarize',
    enabled: true,
    intake: true,
    paneExpanded: true,
  },
  {
    name: 'delivery',
    icon: 'local_shipping',
    enabled: true,
    intake: true,
    paneExpanded: true,
    formName: 'deliveryForm',
    errorKey: 'order:delivery.deliveryInvalid',
  },
  {
    name: 'material-requests',
    icon: 'layers',
    intake: true,
    errorKey: 'order-materials:invalidWarning',
  },
  {
    name: 'materials',
    icon: 'layers',
  },
  {
    name: 'material-assignment',
    icon: 'layers',
    enabled: true,
    admin: true,
    paneExpanded: true,
  },
  {
    name: 'quotes',
    icon: 'euro',
    paneExpanded: true,
  },
  {
    name: 'pricing',
    icon: 'request_quote',
    paneExpanded: true,
    admin: true,
  },
  {
    name: 'billing',
    icon: 'assignment',
    paneExpanded: true,
  },
  {
    name: 'timeline',
    icon: 'view_timeline',
    admin: true,
    paneExpanded: true,
  },
  {
    name: 'review',
    icon: 'summarize',
    intake: true,
    paneExpanded: true,
  },
];

export const intakeTabs = navigation.filter((t) => t.intake).map((t) => t.name);
export const allTabs = navigation.map((t) => t.name);

export function getTabByKey(key: string) {
  return navigation.find((t) => t.name === key);
}

export function isOrderTabGreaterThan(a: string, b: string) {
  assertValidOrderTab(a);
  assertValidOrderTab(b);

  const i = allTabs.indexOf(a);
  const j = allTabs.indexOf(b);
  return i > j;
}

function assertValidOrderTab(navName: string) {
  if (!allTabs.includes(navName)) {
    throw new Error(`'${navName}' is not a valid order tab name`);
  }
}
